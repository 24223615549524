<template id="iframe-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('general-feedback.post_title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!-- Loading placeholder for post -->
      <div v-if="post == null">
        <ion-item lines="none" class="post" button="false">
          <ion-button color="medium" class="upvote" fill="outline" slot="start" disabled>
            <span>
              <ion-icon :icon="caretUp"></ion-icon>
              <ion-label><ion-skeleton-text :animated="currentlyLoading" style="width: 100%"></ion-skeleton-text></ion-label>
            </span>
          </ion-button>
          <ion-label class="post-texts">
            <ion-label class="staff-marker"><ion-skeleton-text :animated="currentlyLoading" style="width: 20%"></ion-skeleton-text></ion-label>
            <h2 class="post-title">
              <ion-skeleton-text :animated="currentlyLoading" style="width: 100%"></ion-skeleton-text>
            </h2>
            <p>
              <ion-chip color="light" class="status" disabled>
                <ion-skeleton-text :animated="currentlyLoading" style="width: 80px"></ion-skeleton-text>
              </ion-chip> 
              <span><ion-skeleton-text :animated="currentlyLoading" style="width: 40%; display: inline-block;"></ion-skeleton-text></span>
            </p>
          </ion-label>
        </ion-item>
        <ion-item lines="none" button="false">
          <ion-label class="post-description">
            <h2><ion-skeleton-text :animated="currentlyLoading" style="width: 20%"></ion-skeleton-text></h2>
            <p>
              <ion-skeleton-text v-for="index in 3" :key="index" :animated="currentlyLoading" :style="`width: ${100-((index - 1)*10)}%`"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </div>

      <!-- Actual post -->
      <div v-else>
        <ion-item lines="none" class="post" button="false">
          <ion-button :color="post.hasVoted ? 'primary' : 'medium'" class="upvote" fill="outline" slot="start" @click.stop="togglePostUpvote(post)">
            <span>
              <ion-icon :icon="caretUp"></ion-icon>
              <ion-label>{{ post.votesCount }}</ion-label>
            </span>
          </ion-button>
          <ion-label class="post-texts">
            <ion-label class="staff-marker" color="primary"><ion-icon v-if="getUserDesign(post).isStaff" :icon="checkmarkCircle" color="primary"></ion-icon> {{ i18n.$t(`general-feedback.username.${ getUserDesign(post).username }`) }}</ion-label>
            <h2 class="post-title">
              <span>{{ post.title }}</span>
            </h2>
            <p>
              <ion-chip class="status" :color="getStatusDesign(post.status).color" :title="i18n.$t(`general-feedback.status.${post.status}`)" disabled>
                <ion-icon size="small" :icon="getStatusDesign(post.status).icon" :alt="i18n.$t(`general-feedback.status.${post.status}`)"></ion-icon>
                <ion-label>{{ i18n.$t(`general-feedback.status.${post.status}`) }} </ion-label>
              </ion-chip>
              <span>· {{ getTimeFromNow(post.createdAt) }}</span>
            </p>
          </ion-label>
        </ion-item>
        <ion-item lines="none" button="false">
          <ion-label class="post-description">
            <h2><b>{{i18n.$t('general-feedback.description')}}</b></h2>
            <p>
              {{ post.description }}
            </p>
          </ion-label>
        </ion-item>
        <ion-item v-if="post.response != null" lines="none" button="false">
          <ion-label class="post-description">
            <ion-label color="primary"><h2><b>{{i18n.$t('general-feedback.response_from')}} {{i18n.$t('general-feedback.username.staff')}} · {{ getTimeFromNow(post.response.respondedAt) }}</b></h2></ion-label>
            <p>
              {{ post.response.text }}
            </p>
          </ion-label>
        </ion-item>
      </div>

      <!-- Loading placeholder for comments -->
      <ion-list v-if="comments == null">
        <ion-list-header>
          <ion-skeleton-text :animated="currentlyLoading" style="width: 30%"></ion-skeleton-text>
        </ion-list-header>
        <ion-item lines="inset" class="comment" v-for="index in 10" :key="index" button="false">
          <ion-label class="comment-texts">
            <ion-label class="staff-marker"><ion-skeleton-text :animated="currentlyLoading" style="width: 20%"></ion-skeleton-text></ion-label>
            <h3><ion-skeleton-text :animated="currentlyLoading" style="width: 35%"></ion-skeleton-text></h3>
            <p>
              <ion-skeleton-text v-for="cindex in 2" :key="cindex" :animated="currentlyLoading" :style="`width: ${100-((cindex - 1)*10)}%`"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- Actual list of comments -->
      <ion-list v-else>
        <ion-list-header>
          <ion-label>{{ (comments != null) ? comments.length : i18n.$t('general-feedback.loading') }} {{i18n.$t(`general-feedback.${ (comments.length == 1) ? 'comment' : 'comments' }`)}}</ion-label>
        </ion-list-header>
        <ion-item :lines="(index < (comments.length - 1)) ? 'inset' : 'full' " class="comment" v-for="(comment, index) in comments" :key="comment.id" button="false">
          <ion-label class="comment-texts">
            <ion-label class="staff-marker" color="primary"><ion-icon v-if="getUserDesign(comment).isStaff" :icon="checkmarkCircle" color="primary"></ion-icon> {{ i18n.$t(`general-feedback.username.${ getUserDesign(comment).username }`) }}</ion-label>
            <h3>{{ getTimeFromNow(comment.createdAt) }}</h3>
            <p>
              {{ comment.content }}
            </p>
          </ion-label>
        </ion-item>

        <form @submit="$event.preventDefault(); if($event.target.checkValidity()) submitComment();">
          <ion-item lines="inset">
            <ion-label position="floating">{{i18n.$t('general-feedback.enter_comment')}}</ion-label>
            <ion-textarea required rows="1" v-model="newComment" auto-grow></ion-textarea>
          </ion-item>
          <ion-item lines="full">
            <ion-buttons class="send-comment">
              <ProgressButton color="success" fill="solid" :disabled="newComment == null || !newComment.length" type="submit" :loading="currentlySubmitting">
                <ion-icon :icon="send"></ion-icon> {{i18n.$t('general-feedback.submit')}}
              </ProgressButton>
            </ion-buttons>
          </ion-item>
        </form>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonList, IonListHeader, IonLabel, IonChip, IonIcon, IonSkeletonText, IonTextarea, modalController } from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';

import ProgressButton from '@/components/ProgressButton.vue';

import { caretUp, chatbubblesOutline, checkmarkCircle, filter, send } from 'ionicons/icons';

import { useStore } from 'vuex';

import { apiErrorToast } from '@/utils/error';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

const GeneralFeedbackModal = defineComponent({
  name: 'GeneralFeedbackModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonList, IonListHeader, IonLabel, IonChip, IonIcon, IonSkeletonText, IonTextarea, ProgressButton },
  props: {
    'id': Number
  },
  setup(props) {
    const i18n = useI18n();

    const { dayjs, dayjsLocale, timezone, isReady } = useDayjs();

    const store = useStore();

    const closeModal = function(){
      modalController.dismiss();
    }

    const currentlyLoading = ref(true);

    const currentlySubmitting = ref(false);
    const newComment = ref(null);

    const post = ref(null);

    const comments = ref(null);

    const fetchPost = function(){
      return store.dispatch('generalFeedback/fetchPost', props.id)
      .then((newPost) => post.value = newPost)
      .catch((error) => {
        post.value = null;
        apiErrorToast(i18n, error, true).then(() => closeModal())
        throw error;
      })
     ;
    }

    const fetchComments = function(){
      return store.dispatch('generalFeedback/fetchComments', props.id)
      .then((newComments) => comments.value = newComments)
      .catch((error) => {
        comments.value = null;
        apiErrorToast(i18n, error, true).then(() => closeModal())
      });
    }

    const getStatusDesign = function(status){
      return store.getters['generalFeedback/getStatusDesign'](status);
    }

    const getUserDesign = function(entry) {
      if (entry != null)
        return store.getters['generalFeedback/getUserDesign'](entry.user);
      return {};
    }

    const getTimeFromNow = computed(() => {
      if (isReady.value) {
        return function(timestamp) {
          return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone).fromNow();
        }
      } else {
        return function(timestamp) {
          return dayjs.utc(timestamp).tz(timezone).fromNow();
        }
      }
    })

    const togglePostUpvote = function(post) {
      if (post.hasVoted) {
        store.dispatch('generalFeedback/unVotePost', post.number)
        .then(() => {
          post.hasVoted = false;
          post.votesCount--;
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true);
        });
      } else {
        store.dispatch('generalFeedback/votePost', post.number)
        .then(() => {
          post.hasVoted = true;
          post.votesCount++;
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true);
        });
      }
    }

    const submitComment = function() {
      currentlySubmitting.value = true;
      store.dispatch('generalFeedback/addComment', {
        id: props.id,
        content: newComment.value
      })
      .then(() => {
        newComment.value = null;
        fetchComments().catch(() => {});
      })
      .catch((error) => {
        apiErrorToast(i18n, error, true);
      })
      .finally(() => {
        currentlySubmitting.value = false;
      })
    }

    onMounted(() => {
      currentlyLoading.value = true;
      fetchPost()
      .then(() => fetchComments()).catch(() => {})
      .finally(() => { 
        currentlyLoading.value = false;
      });
    });

    return { i18n, currentlyLoading, currentlySubmitting, newComment, post, comments, closeModal, getStatusDesign, getTimeFromNow, getUserDesign, togglePostUpvote, submitComment, caretUp, chatbubblesOutline, checkmarkCircle, filter, send };
  }
});

export async function openGeneralFeedbackModal(component, id){
  if (component != null && id != null) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          id
        },
      })
    modal.present();
    return modal.onDidDismiss();
  }
}

export default GeneralFeedbackModal;
</script>

<style scoped>
.upvote {
  height: auto;
  font-size: 20px;
  min-width: 55px;
  margin-inline-end: 10px;
}

.upvote span {
  display: flex;
  flex-flow: column;
  padding-top: 5px;
  padding-bottom: 10px;
}

ion-list, ion-item {
  background: var(--ion-background-color, #fff);
  --background: var(--ion-background-color, #fff);
}

.post-texts > *, .comment-texts > * {
  margin-bottom: 10px;
}

.post-description h2 {
  margin-bottom: 10px;
}

.post-title, .post-description p, .comment-texts p {
  overflow-wrap: normal;
  white-space: normal;
  word-wrap: normal;
}

.status {
  padding: 0px 10px;
  height: 2em;
  line-height: 2em;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 4px;
  background: rgba(var(--ion-color-base-rgb), 0.6);
}

.status ion-icon {
  margin-left: 1px;
  margin-right: 1px;
}

.status ion-label, .status ion-icon {
  color: #fff;
}

.status ion-label.hidden {
  max-width: 0px;
  margin-left: 0px;
  transition: max-width 0.1s ease-in-out, margin-left 0.1s ease-in-out;
}

.post:hover .status ion-label.hidden {
  max-width: 500px;
  margin-left: 5px;
}

.staff-marker {
  font-size: 0.75em;
}

.staff-marker ion-icon {
  vertical-align: middle;
  padding-bottom: 3px;
}

.invisible {
  visibility: hidden;
}

.send-comment {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
  width: 100%;
}

.send-comment ion-button ion-icon {
  margin-right: 5px;
}
</style>