<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('general-feedback.title')" />
    </ion-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="fetchPosts().finally($event.target.complete())">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card class="anonymous-hint">
        <ion-card-header>
          <ion-card-subtitle>{{ i18n.$t('general-feedback.vote-hint') }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content color="dark">
          {{ i18n.$t('general-feedback.anonymous-hint') }}
        </ion-card-content>
      </ion-card>
      <div class="search">
        <ion-chip color="tertiary" class="filter-view" @click="openPopover(true, $event, (view) => currentFilterView = view, filterViewOptions, i18n.$t('general-feedback.filter_view'))">
          <ion-icon :icon="filter"></ion-icon>
          <ion-label>{{ i18n.$t(`general-feedback.views.${currentFilterView}`) }} </ion-label>
        </ion-chip>
        <ion-searchbar
          maxlength="100"
          show-clear-button="always"
          :placeholder="`${i18n.$t('general-feedback.search')}: ${i18n.$t('general-feedback.enter_title')}`"
          @keyup="blurOnEnter"
          @ionInput="searchTerm = $event.target.value"
          @ionBlur="fetchPosts()"
          @ionClear="searchTerm = null; fetchPosts();">
        </ion-searchbar>
      </div>

      <!-- Loading placeholder -->
      <ion-list v-if="posts == null">
        <ion-list-header>
          <ion-skeleton-text :animated="currentlyLoading" style="width: 20%"></ion-skeleton-text>
        </ion-list-header>
        <ion-item lines="full" class="post" v-for="index in 10" :key="index" detail button="false">
          <ion-button color="medium" class="upvote" fill="outline" slot="start" disabled>
            <span>
              <ion-icon :icon="caretUp"></ion-icon>
              <ion-label><ion-skeleton-text :animated="currentlyLoading" style="width: 100%"></ion-skeleton-text></ion-label>
            </span>
          </ion-button>
          <ion-label class="post-texts">
            <ion-label class="staff-marker"><ion-skeleton-text :animated="currentlyLoading" style="width: 20%"></ion-skeleton-text></ion-label>
            <h3 class="post-title">
              <span><ion-skeleton-text :animated="currentlyLoading" style="width: 70%"></ion-skeleton-text></span>
              <span class="comments">
                <ion-icon :icon="chatbubblesOutline"></ion-icon> <ion-skeleton-text :animated="currentlyLoading" style="width: 20px; display: inline-block;"></ion-skeleton-text>
              </span>
            </h3>
            <p class="post-description">
              <ion-chip color="light" class="status" disabled>
                <ion-skeleton-text :animated="currentlyLoading" style="width: 10px"></ion-skeleton-text>
              </ion-chip>
              <span><ion-skeleton-text :animated="currentlyLoading" style="width: 80%; display: inline-block;"></ion-skeleton-text></span>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- Actual List of Posts -->
      <ion-list v-else>
        <ion-list-header>
          <ion-label>{{ (posts != null) ? posts.length : i18n.$t('general-feedback.loading') }} {{i18n.$t(`general-feedback.${ (posts.length == 1) ? 'post' : 'posts' }`)}} {{ i18n.$t('general-feedback.found')}}</ion-label>
        </ion-list-header>
        <ion-item lines="full" class="post" v-for="post in posts" :key="post.id" detail button @click.stop="openPost(post.number)">
          <ion-button :color="post.hasVoted ? 'primary' : 'medium'" class="upvote" fill="outline" slot="start" @click.stop="togglePostUpvote(post)">
            <span>
              <ion-icon :icon="caretUp"></ion-icon>
              <ion-label>{{ post.votesCount }}</ion-label>
            </span>
          </ion-button>
          <ion-label class="post-texts">
            <ion-label class="staff-marker" color="primary"><ion-icon v-if="getUserDesign(post).isStaff" :icon="checkmarkCircle" color="primary"></ion-icon> {{ i18n.$t(`general-feedback.username.${ getUserDesign(post).username }`) }}</ion-label>
            <h3 class="post-title">
              <span>{{ post.title }}</span>
              <span class="comments">
                <ion-icon :icon="chatbubblesOutline"></ion-icon> {{ post.commentsCount }}
              </span>
            </h3>
            <p class="post-description">
              <ion-chip class="status" :color="getStatusDesign(post.status).color" :title="i18n.$t(`general-feedback.status.${post.status}`)" disabled>
                <ion-icon size="small" :icon="getStatusDesign(post.status).icon" :alt="i18n.$t(`general-feedback.status.${post.status}`)"></ion-icon>
                <ion-label :class="getStatusDesign(post.status).icon ? 'hidden' : ''">{{ i18n.$t(`general-feedback.status.${post.status}`) }} </ion-label>
              </ion-chip>
              <span>{{ post.description }}</span>
            </p>
          </ion-label>
        </ion-item>
        <ion-card class="create-feedback" v-if="!feedbackSubmitted">
          <ion-card-header>
            <ion-card-title>{{i18n.$t('general-feedback.no_fitting_entry')}}</ion-card-title>
            <ion-card-subtitle>{{i18n.$t('general-feedback.create_entry')}}:</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <form @submit="$event.preventDefault(); if($event.target.checkValidity()) submitFeedback();">
              <ion-item>
                <ion-label position="floating">{{i18n.$t('general-feedback.enter_title')}}</ion-label>
                <ion-textarea rows="1" required v-model="newPostTitle" maxlength="100" auto-grow></ion-textarea>
              </ion-item>
              <ion-item>
                <ion-label position="floating">{{i18n.$t('general-feedback.enter_description')}}</ion-label>
                <ion-textarea rows="1" v-model="newPostDescription" auto-grow></ion-textarea>
              </ion-item>
              <ion-buttons>
                <ProgressButton color="success" fill="solid" :disabled="newPostTitle == null || !newPostTitle.length" type="submit" :loading="currentlySubmitting">
                  <ion-icon :icon="send"></ion-icon> {{i18n.$t('general-feedback.submit')}}
                </ProgressButton>
              </ion-buttons>
            </form>
          </ion-card-content>
        </ion-card>
        <ion-card class="create-feedback" v-else>
          <ion-card-header>
            <ion-card-title color="success">{{i18n.$t('general-feedback.thank_you')}}</ion-card-title>
            <ion-card-subtitle>{{i18n.$t('general-feedback.entry_sent')}}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-buttons>
              <ion-button color="secondary" fill="solid" @click="resetNewEntry()"><ion-icon :icon="refresh"></ion-icon> {{i18n.$t('general-feedback.reset')}}</ion-button>
            </ion-buttons>
          </ion-card-content>
        </ion-card>
      </ion-list>
    </ion-content>

    <!-- Popover to select filters -->
    <ion-popover
      :is-open="popoverOpenRef"
      css-class="popover-select"
      :event="popoverEventRef"
      @didDismiss="openPopover(false)">
    <ion-list>
      <ion-list-header><h3><b>{{ popoverTitle }}</b></h3></ion-list-header>
      <ion-item detail="false" lines="none" button class="popover-item" v-for="(descriptor, option) in popoverOptions" :key="option" @click="(callbackFunction != null) ? callbackFunction(option) : true ; openPopover(false);">
        {{ descriptor }}
      </ion-item>
    </ion-list>
  </ion-popover>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonSearchbar, IonList, IonListHeader, IonItem, IonSkeletonText, IonButtons, IonButton, IonLabel, IonIcon, IonChip, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonTextarea, IonPopover, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { defineComponent, onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

import ProgressButton from '@/components/ProgressButton.vue';

import MainToolbar from '@/components/MainToolbar.vue';
import { openGeneralFeedbackModal, default as modalComponent } from '@/components/GeneralFeedbackModal.vue';

import { apiErrorToast } from '@/utils/error';

import { caretUp, chatbubblesOutline, checkmarkCircle, filter, send, refresh } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useRouter } from 'vue-router';

import _ from 'lodash';

export default defineComponent({
  name: 'GeneralFeedback',
  components: { IonHeader, IonContent, IonPage, IonSearchbar, IonList, IonListHeader, IonItem, IonSkeletonText, IonButtons, IonButton, IonLabel, IonIcon, IonChip, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonTextarea, IonPopover, IonRefresher, IonRefresherContent, ProgressButton, MainToolbar },
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const store = useStore();

    const currentFilterView = computed({
      get: function() {
        return store.getters['generalFeedback/getView'];
      },
      set: function(newView) {
        store.commit('generalFeedback/setView', newView);
      }
    });

    watch(currentFilterView, () => fetchPosts());

    const filterViewOptions = computed(() => {
      let newOptions = {};
      for (let option of store.getters['generalFeedback/viewOptions']) {
        newOptions[option] = i18n.$t(`general-feedback.views.${option}`);
      }
      return newOptions;
    });

    const searchTerm = ref(null);

    const newPostTitle = ref(null);
    const newPostDescription = ref(null);

    const currentlySubmitting = ref(false);
    const feedbackSubmitted = ref(false);

    watch(searchTerm, (newTerm, oldTerm) => {
      if (newPostTitle.value == null || _.isEqual(oldTerm, newPostTitle.value)) {
        if (newTerm == null) {
          newPostTitle.value = null;
        } else {
          newPostTitle.value = newTerm.substring(0, 100);
        }
      }
    });

    const currentlyLoading = ref(true);

    const posts = ref(null);

    const fetchPosts = function(){
      currentlyLoading.value = true;
      return store.dispatch('generalFeedback/fetchPosts', {
        query: searchTerm.value || undefined,
        view: currentFilterView.value || undefined
      })
      .then((newPosts) => posts.value = newPosts)
      .catch((error) => {
        posts.value = null;
        apiErrorToast(i18n, error, true);
      })
      .finally(() => currentlyLoading.value = false);
    }

    const getStatusDesign = function(status){
      return store.getters['generalFeedback/getStatusDesign'](status);
    }

    const getUserDesign = function(post) {
      if (post != null)
        return store.getters['generalFeedback/getUserDesign'](post.user);
      return {};
    }

    const openPost = function(id) {
      openGeneralFeedbackModal(modalComponent, id).then(() => fetchPosts());
    }

    const togglePostUpvote = function(post) {
      if (post.hasVoted) {
        store.dispatch('generalFeedback/unVotePost', post.number)
        .then(() => {
          post.hasVoted = false;
          post.votesCount--;
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true);
        });
      } else {
        store.dispatch('generalFeedback/votePost', post.number)
        .then(() => {
          post.hasVoted = true;
          post.votesCount++;
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true);
        });
      }
    }

    const submitFeedback = function() {
      currentlySubmitting.value = true;
      store.dispatch('generalFeedback/createPost', {
        title: newPostTitle.value,
        description: newPostDescription.value || undefined
      })
      .then(() => {
        feedbackSubmitted.value = true;
        fetchPosts();
      })
      .catch((error) => {
        feedbackSubmitted.value = false;
        apiErrorToast(i18n, error, true);
      })
      .finally(() => {
        currentlySubmitting.value = false;
      })
    }

    const resetNewEntry = function(){
      feedbackSubmitted.value = false;
      newPostTitle.value = null;
      newPostDescription.value = null;
    }

    const blurOnEnter = function(event){
      //If enter key was pressed, blur
      if(event.key === 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    }

    const popoverOpenRef = ref(false);
    const popoverEventRef = ref();
    const popoverOptions = ref([]);
    const popoverTitle = ref();
    const callbackFunction = ref();

    const openPopover = function(state, event, newCallbackFunction, newPopoverOptions, newPopoverTitle) {
      if ( popoverOpenRef.value != state ){ //Only execute change, when it needs to be changed
        popoverEventRef.value = event;
        if ( state == true ){
          if ( event !== undefined && newCallbackFunction != null && newPopoverOptions != null && newPopoverTitle != null){ //Only display when it can be positioned correctly with the event
            popoverOptions.value = newPopoverOptions;
            popoverTitle.value = newPopoverTitle;
            callbackFunction.value = newCallbackFunction;
            popoverOpenRef.value = true;
          }
        } else {
          popoverOpenRef.value = false;
        }
      }
    }

    const handleResize = function(){
      openPopover(false); //Close popover when resizing or rotating device screen for correct display
    }

    //TODO Refresh posts after each action that alters the post list

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      fetchPosts();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return { i18n, router, store, caretUp, currentFilterView, filterViewOptions, searchTerm, newPostTitle, newPostDescription, currentlyLoading, currentlySubmitting, posts, popoverOpenRef, popoverEventRef, popoverOptions, popoverTitle, openPopover, callbackFunction, feedbackSubmitted, fetchPosts, getStatusDesign, openPost, togglePostUpvote, blurOnEnter, submitFeedback, resetNewEntry, getUserDesign, chatbubblesOutline, checkmarkCircle, filter, send, refresh };
  }
});
</script>

<style scoped>
.upvote {
  height: auto;
  font-size: 20px;
  min-width: 55px;
  margin-inline-end: 10px;
}

.upvote span {
  display: flex;
  flex-flow: column;
  padding-top: 5px;
  padding-bottom: 10px;
}

ion-list, ion-item {
  background: var(--ion-background-color, #fff);
  --background: var(--ion-background-color, #fff);
}

.comments ion-icon {
  vertical-align: top;
}

.comments {
  position: absolute;
  right: 0px;
  top: 0px;
}

.post-texts > * {
  margin-bottom: 5px;
}

.post-title {
  position: relative;
  padding-right: 55px;
}

.search {
  position: sticky;
  top: 0px;
  z-index: 1000;
  width: 100%;
  background: var(--ion-background-color, #fff);
}

.search > ion-chip {
  margin-left: 16px;
  margin-top: 10px;
}

.anonymous-hint {
  color: var(--ion-color-dark, #000);
}

.status {
  padding: 0px 10px;
  height: 2em;
  line-height: 2em;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 4px;
  background: rgba(var(--ion-color-base-rgb), 0.6);
}

.status ion-icon {
  margin-left: 1px;
  margin-right: 1px;
}

.status ion-label, .status ion-icon {
  color: #fff;
}

.status ion-label.hidden {
  max-width: 0px;
  margin-left: 0px;
  transition: max-width 0.1s ease-in-out, margin-left 0.1s ease-in-out;
}

.post:hover .status ion-label.hidden {
  max-width: 500px;
  margin-left: 5px;
}

.create-feedback ion-item {
  background: transparent;
  --background: transparent;
}

.create-feedback ion-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.create-feedback ion-button ion-icon {
  margin-right: 5px;
}

.staff-marker {
  font-size: 0.75em;
}

.staff-marker ion-icon {
  vertical-align: middle;
  padding-bottom: 3px;
}

.invisible {
  visibility: hidden;
}
</style>
